import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { url } from "../../Api";
import { PageContext } from "../../Context/PageContextProvider";
import { ViewerForm } from "../Helper/ViewerForm";

export const SalesUpLine = ({ userId }) => {
  const { userInfo } = useContext(PageContext);
  const [userAccount, setUserAccount] = useState({});

  useEffect(async () => {
    await axios
      .get(`${url}/user/profile/byID/${userId}`, {
        headers: {
          Accept: "application/json",
          Authorization: `bearer ${userInfo?.token}`,
        },
      })
      .then((res) => {
        setUserAccount(res.data.data.upline);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [userInfo?.token, userId]);
  return (
    <>
      {userAccount ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-5">
          {/* Persona details*/}
          <div>
            <h4 className="font-medium text-lg pb-4">Personal Details</h4>
            <ViewerForm
              label="Full Name"
              placeholder={userAccount.fullname}
              type="input"
            />
            <ViewerForm
              label="Email"
              placeholder={userAccount.email}
              type="input"
            />
            <ViewerForm
              label="Phone number"
              placeholder={userAccount.phone}
              type="input"
            />
            {/* <ViewerForm
    label="Realtor sales"
    placeholder={sales?.length}
    type="input"
  /> */}

            <ViewerForm
              label="Gender"
              placeholder={userAccount.gender}
              type="input"
            />
            <ViewerForm
              label="Referral ID"
              placeholder={userAccount.refID}
              type="input"
            />
            <ViewerForm
              label="Date of birth"
              placeholder={userAccount.DOB}
              type="input"
            />
            <ViewerForm
              label="State of origin"
              placeholder={userAccount.stateOfOrigin}
              type="input"
            />
            <ViewerForm
              label="House Address"
              placeholder={userAccount.houseAdress}
              type="textarea"
            />
            <ViewerForm
              label="Office Address"
              placeholder={userAccount.officeAdress}
              type="textarea"
            />
            <ViewerForm
              label="About"
              placeholder={userAccount.about}
              type="textarea"
            />
          </div>
          {/* social */}
          <div>
            <h4 className="font-medium text-lg pb-4">Social Media</h4>
            <ViewerForm
              label="Facebook"
              placeholder={userAccount.socialDetails?.facebookURL}
              type="input"
            />
            <ViewerForm
              label="instagram"
              placeholder={userAccount?.socialDetails?.instagramURL}
              type="input"
            />
            <ViewerForm
              label="Twitter"
              placeholder={userAccount?.socialDetails?.twitterURL}
              type="input"
            />
            <ViewerForm
              label="Youtube"
              placeholder={userAccount?.socialDetails?.youtubeURL}
              type="input"
            />
            <ViewerForm
              label="Whatsapp"
              placeholder={userAccount.socialDetails?.whatsappURL}
              type="input"
            />
          </div>

          {/* bank details */}
          <div>
            <h4 className="font-medium text-lg pb-4">Bank details</h4>
            <ViewerForm
              label="Account Number"
              placeholder={userAccount?.bankDetails?.bankAccount}
              type="input"
            />
            <ViewerForm
              label="Account Name"
              placeholder={userAccount?.bankDetails?.bankHolder}
              type="input"
            />
            <ViewerForm
              label="Bank Name"
              placeholder={userAccount?.bankDetails?.bankName}
              type="input"
            />
          </div>
        </div>
      ) : (
        <div>Not found</div>
      )}
    </>
  );
};
